import { Amplify, Auth } from "aws-amplify";

import { withAuthenticator } from "@aws-amplify/ui-react";
import { CognitoUserAmplify } from "@aws-amplify/ui";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";
import { Form } from "./Form";
import { useMemo, useState } from "react";
import React from "react";
import { ICredentials } from "@aws-amplify/core";

// Choose an OAuth config based on environment
const { host } = window.location;

// Fix issues with multiple redirect urls.
// Try to figure out which one to use...
if (awsExports.oauth.redirectSignIn.includes(",")) {
  const filterHost = (url: string) => new URL(url).host === host;
  const redirectSignIn = awsExports.oauth.redirectSignIn
    .split(",")
    .filter(filterHost)
    .shift();
  const redirectSignOut = awsExports.oauth.redirectSignOut
    .split(",")
    .filter(filterHost)
    .shift();
  const overrides = {
    ...(redirectSignIn ? { redirectSignIn } : {}),
    ...(redirectSignOut ? { redirectSignOut } : {}),
  };
  awsExports.oauth = {
    ...awsExports.oauth,
    ...overrides,
  };
}

Amplify.configure(awsExports);

function App({
  signOut,
  user,
}: {
  signOut: () => void;
  user: CognitoUserAmplify;
}) {
  const [credentials, setCredentials] = useState<ICredentials | undefined>(
    undefined
  );
  const [error, setError] = useState(undefined);
  useMemo(() => {
    Auth.currentCredentials().then(setCredentials, setError);
  }, [])

  if (credentials !== undefined) {
    return (
      <>
        <h1>
          Hello{" "}
          {user.getSignInUserSession()?.getIdToken().payload["given_name"] ??
            "unknown user"}
        </h1>
        <Form credentials={credentials}/>
        <div>
          <button onClick={signOut}>Sign out</button>
        </div>
      </>
    );
  }

  if (error !== undefined) {
    return <div>Error: {error}</div>;
  }

  return <div>Loading...</div>;
}

export default withAuthenticator(App, { hideSignUp: true });
