import {
  EC2Client,
  DescribeInstanceStatusCommand,
  StartInstancesCommand,
  StopInstancesCommand,
} from "@aws-sdk/client-ec2";
import { ICredentials } from "@aws-amplify/core";

function makeClient(credentials: ICredentials) {
  const region = "us-east-1";
  return new EC2Client({
    region,
    credentials,
  });
}

async function getFactorioStatus(client: EC2Client, instanceID: string) {
  const command = new DescribeInstanceStatusCommand({
    InstanceIds: [instanceID],
    IncludeAllInstances: true,
  });

  const resp = await client.send(command);
  const state = resp.InstanceStatuses?.shift()?.InstanceState?.Name;
  if (state === undefined) {
    console.error("failed to get status", resp);
    throw new Error("failed");
  }
  return state;
}

export class FactorioInstance {
  private statusChangeCallback?: (status: string) => void;

  private constructor(
    private instanceID: string,
    private status: string,
    private client: EC2Client
  ) {}

  public static async create(instanceID: string, credentials: ICredentials) {
    const client = makeClient(credentials);
    const status = await getFactorioStatus(client, instanceID);
    return new FactorioInstance(instanceID, status, client);
  }

  async refreshStatus() {
    this.status = await this.fetchStatus();
    return this.status;
  }

  getStatus() {
    return this.status;
  }

  onStatusChange(callback: (status: string) => void) {
    this.statusChangeCallback = callback;
  }

  async start() {
    await this.fetchStatus();
    if (this.status !== "stopped") {
      console.log("can only start if status is 'stopped'")
      return
    }

    const command = new StartInstancesCommand({
      InstanceIds: [this.instanceID],
    });
    const resp = await this.client.send(command);
    const currentState = resp.StartingInstances?.shift()?.CurrentState?.Name;
    if (currentState !== undefined) {
      this.statusChangeCallback?.(currentState);
    }
  }

  async stop() {
    await this.fetchStatus();
    if (this.status !== "running") {
      console.log("can only stop if status is 'running'")
      return
    }

    const command = new StopInstancesCommand({
      InstanceIds: [this.instanceID],
    });
    const resp = await this.client.send(command);
    const currentState = resp.StoppingInstances?.shift()?.CurrentState?.Name;
    if (currentState !== undefined) {
      this.statusChangeCallback?.(currentState);
    }
  }

  private async fetchStatus() {
    const status = await getFactorioStatus(this.client, this.instanceID);
    this.statusChangeCallback?.(status);
    return status;
  }
}
