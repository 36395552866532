import { useEffect, useMemo, useState } from "react";
import { ICredentials } from "@aws-amplify/core";
import moment, { Moment } from "moment";

import { FactorioInstance } from "./factorio-instance";

moment.relativeTimeThreshold("s", 60);
moment.relativeTimeThreshold("ss", 0);

function useInstance(credentials: ICredentials, instanceID: string) {
  const [instance, setInstance] = useState<FactorioInstance | undefined>(
    undefined
  );
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [timestamp, setTimestamp] = useState<Moment | undefined>(undefined);
  const [timeago, setTimeago] = useState<string | undefined>(undefined);
  useMemo(async () => {
    if (credentials !== undefined) {
      const instance = await FactorioInstance.create(instanceID, credentials);
      setInstance(instance);
      setStatus(instance.getStatus());
      const now = moment();
      setTimestamp(now);
      setTimeago("just now");
      instance.onStatusChange((newStatus) => {
        setStatus(newStatus);
        const now = moment();
        setTimestamp(now);
        setTimeago(now.fromNow());
      });
    } else {
      setInstance(undefined);
    }
  }, [credentials, instanceID]);
  useEffect(() => {
    const subscription = setInterval(() => {
      console.log("updating timeago");
      setTimeago(timestamp?.fromNow());
    }, 1000);
    return () => {
      console.log("clearing subscription")
      clearInterval(subscription);
    };
  }, [timestamp]);

  return { instance, status, timeago };
}

function StartStopButton({
  instance,
  status,
}: {
  instance: FactorioInstance;
  status: string | undefined;
}) {
  switch (status) {
    case "running":
      return (
        <button onClick={async () => await instance.stop()}>Stop Server</button>
      );
    case "stopped":
      return (
        <button onClick={async () => await instance.start()}>
          Start Server
        </button>
      );
    default:
      return null;
  }
}

export function Form({ credentials }: { credentials: ICredentials }) {
  const instanceID = "i-0265a991c649562cd";
  const { instance, status, timeago } = useInstance(credentials, instanceID);
  return (
    <>
      <div>
        {instance === undefined
          ? "getting status ..."
          : `Server status: "${status}", refreshed ${timeago}`}
      </div>
      {instance === undefined ? undefined : (
        <>
          <button onClick={() => instance.refreshStatus()}>
            Refresh status
          </button>
          <StartStopButton instance={instance} status={status} />
        </>
      )}
    </>
  );
}
